import React, {useRef} from 'react'
import Text from '@component/Text/Text'
import RoadmapInfoImageEn from '@image/token/image_token_landing_token_roadmap_info_en.webp'
import RoadmapInfoTabletImageEn from '@image/token/image_token_landing_token_roadmap_info_tablet_en.webp'
import RoadmapInfoMobileImageEn from '@image/token/image_token_landing_token_roadmap_info_mobile_en.webp'
import RoadmapInfoImageKo from '@image/token/image_token_landing_token_roadmap_info_ko.webp'
import RoadmapInfoTabletImageKo from '@image/token/image_token_landing_token_roadmap_info_tablet_ko.webp'
import RoadmapInfoMobileImageKo from '@image/token/image_token_landing_token_roadmap_info_mobile_ko.webp'
import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import {useIsVisible} from '@hook/useIsVisible'
import FadeInView from '@component/animation/fadeIn/FadeInView'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import WizImage from '@component/images/WizImage'
import WizImg from '@component/images/WizImg'
interface IProps {
    className?: string
}

const TokenInfoRoadmapContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const language = useRouter()?.locale
    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)
    return (
        <div
            ref={fadeAnimationRef}
            className={`bg-[#1F1F26] layout-full_desktop py-[200px] lg2:py-[150px] md:py-[100px] sm:py-[70px] ${className}`}>
            <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN}>
                <Text
                    className={
                        'text-white text-center text-landing_heading2 md:text-landing_body_large md:font-semibold !font-landing'
                    }>
                    {t('landingTokenSale.TokenInfo.roadmap.title')}
                </Text>
            </FadeInView>
            <div className={'max-w-[1920px] mx-auto'}>
                <WizImg
                    src={language === 'ko' ? RoadmapInfoImageKo.src : RoadmapInfoImageEn.src}
                    className={'md:hidden'}
                />
                <WizImg
                    src={language === 'ko' ? RoadmapInfoTabletImageKo.src : RoadmapInfoTabletImageEn.src}
                    className={'hidden md:flex sm:hidden'}
                />
                <WizImg
                    src={language === 'ko' ? RoadmapInfoMobileImageKo.src : RoadmapInfoMobileImageEn.src}
                    className={'hidden sm:flex'}
                />
            </div>
        </div>
    )
}
export default TokenInfoRoadmapContainer
