import React, {useRef} from 'react'
import Text from '@component/Text/Text'
import Link from 'next/link'
import BinanceLogo from '@svg/token/logo_exchange_binance_dark.svg'
import BybitLogo from '@svg/token/logo_exchange_bybit_dark.svg'
import BitMEXLogo from '@svg/token/logo_exchange_bitmex_dark.svg'
import CoinbaseLogo from '@svg/token/logo_exchange_coinbase_dark.svg'
import OKXLogo from '@svg/token/logo_exchange_okx_dark.svg'
import BitgetLogo from '@svg/token/logo_exchange_bitget_dark.svg'
import PhemexLogo from '@svg/token/logo_exchange_phemex_dark.svg'
import MexcLogo from '@svg/token/logo_exchange_mexc_dark.svg'
import BingxLogo from '@svg/token/logo_exchange_bingx_dark.svg'
import UpbitLogo from '@svg/token/logo_exchange_upbit_dark.svg'
import BithumbLogo from '@svg/token/logo_exchange_bithumb_dark.svg'
import WooxLogo from '@svg/token/logo_exchange_woox_dark.svg'
import BlofinLogo from '@svg/token/logo_exchange_blofin_dark.svg'
import {useTranslation} from 'next-i18next'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import useWindowSize from '@hook/useWindowSize'
import FadeInView from '@component/animation/fadeIn/FadeInView'
import {useIsVisible} from '@hook/useIsVisible'
import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
}

interface IExchange {
    id: string
    logo: any
    url: string
}

const WEB_LOGO_WIDTH = 170
const WEB_LOGO_HEIGHT = 35
const MOBILE_LOGO_WIDTH = 97
const MOBILE_LOGO_HEIGHT = 20

const TokenServiceSupportExchangeContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {getExchange} = useQueryGetExchanges()
    const {isMd} = useWindowSize()

    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)

    const exchanges: IExchange[] = [
        {
            id: 'binance',
            logo: (
                <BinanceLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.binance.com/',
        },
        {
            id: 'bybit',
            logo: (
                <BybitLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.bybit.com/',
        },
        {
            id: 'bitmex',
            logo: (
                <BitMEXLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.bitmex.com/',
        },
        {
            id: 'coinbase',
            logo: (
                <CoinbaseLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.coinbase.com/',
        },
        {
            id: 'okex',
            logo: (
                <OKXLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.okx.com/',
        },
        {
            id: 'bitget',
            logo: (
                <BitgetLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.bitget.com/',
        },
        {
            id: 'blofin',
            logo: (
                <BlofinLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.blofin.com/',
        },

        {
            id: 'mexc',
            logo: (
                <MexcLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.mexc.com/',
        },
        {
            id: 'bingx',
            logo: (
                <BingxLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://bingx.com/',
        },
        {
            id: 'upbit',
            logo: (
                <UpbitLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://upbit.com/',
        },
        {
            id: 'bithumb',
            logo: (
                <BithumbLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.bithumb.com/',
        },
        {
            id: 'woox',
            logo: (
                <WooxLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.woo.org/',
        },
        {
            id: 'phemex',
            logo: (
                <PhemexLogo
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://phemex.com/',
        },
    ]

    return (
        <div className={`layout-full_desktop max-w-[1280px] ${className}`}>
            <div
                className={
                    'py-[150px] md:py-[100px] sm:py-[70px] px-[20px] lg2:px-[40px] md:px-[20px] space-y-[100px] md:space-y-[50px]'
                }>
                <div ref={fadeAnimationRef} className={'space-y-[40px] md:space-y-[20px]'}>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.SERVICE}>
                        <Text
                            className={
                                'text-white text-landing_heading2 md:text-landing_body_large text-center md:font-semibold !font-landing'
                            }>
                            {t('landingTokenSale.TokenService.partnerships.title')}
                        </Text>
                    </FadeInView>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.SERVICE} delay={'delay-500'}>
                        <Text
                            className={
                                'px-[200px] lg2:px-[90px] md:px-0 text-[#C9CAC3] typo-landing_body_medium text-center md:typo-landing_body_caption'
                            }>
                            {t('landingTokenSale.TokenService.partnerships.desc')}
                        </Text>
                    </FadeInView>
                </div>

                <div className={'grid grid-cols-4 md:grid-cols-3 gap-[32px] md:gap-[10px]'}>
                    {exchanges?.map(exchangeItem => (
                        <Link
                            href={getExchange(exchangeItem?.id)?.ref_url ?? exchangeItem?.url}
                            key={exchangeItem?.id}
                            className={
                                ' flex justify-center items-center bg-[#1F1F29] rounded-[10px] py-[40px] md:py-[20px]'
                            }
                            onClick={() => {
                                createLog('event', 'token_landing_exchange_support', {exchange: exchangeItem?.id})
                            }}
                            target={'_blank'}>
                            {exchangeItem?.logo}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default TokenServiceSupportExchangeContainer
